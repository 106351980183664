<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="浙江宁波（省）" name="first">
        <ningbo-province-list></ningbo-province-list>
      </el-tab-pane>
      <el-tab-pane label="浙江宁波（市）" name="second">
        <ningbo-city-list></ningbo-city-list>
      </el-tab-pane>
      <el-tab-pane label="浙江宁波（新）" name="third">
        <ningbo-shi-list></ningbo-shi-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import NingboCityList from "@/views/passport/ningbo/NingboCityList";
import NingboProvinceList from "@/views/passport/ningbo/NingboProvinceList";
import NingboShiList from "@/views/passport/ningbo/NingboShiList";

export default {
  components: { NingboCityList, NingboProvinceList,NingboShiList },
  data() {
    return {
      activeName: "first",
    };
  },
};
</script>

<style scoped></style>
